import React from "react"
import Layout from "../components/layout"
import { css } from "@emotion/core"
import MailchimpForm from "../components/mailchimp_form"

export default function Blog(props) {
  return (
    <Layout>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 20px;
        `}
      >
        <p>
          Programming Thoughts is my newsletter that contains ideas I’m exploring and topics I'm learning.
          <br />
          <br />

          It may also include programming articles, courses, and websites that I recommend, as well as articles that I’ve written, programming books that I’m reading, and useful things I’ve learned along the way.
          <br />
          <br />
        </p>
        <MailchimpForm />
      </div>
    </Layout>
  )
}
